// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// If you want to add something do it here
//@import "custom";

$diagDatabaseTableFirstRowWidth: 30;
$diagDatabaseTableThirdRowWidth: 30;
$manufacturersTableLastRow: 10;
$measurementTasksLastRow: 10;
$updateGroupsTableLastRow: 10;
$branchesTableLastRow: 10;
$branchesTableFirstRow: 15;
$branchesTableSecondRow: 15;
$branchesTableThirdRow: 10;

#diagDatabaseTable tr th:first-child {
  width: percentage($diagDatabaseTableFirstRowWidth / 100);
}

#diagDatabaseTable tr th:nth-child(5) {
  width: percentage($diagDatabaseTableFirstRowWidth / 100);
}

#diagDatabaseTable tr th {
  width: percentage(
    (
        100-$diagDatabaseTableFirstRowWidth -
          $diagDatabaseTableThirdRowWidth
      )/4/100
  );
}

#branchesTable tr th:last-child {
  width: percentage($branchesTableLastRow / 100);
}

#branchesTable tr th:first-child  {
  width: percentage($branchesTableFirstRow / 100);
}

#branchesTable tr th:nth-child(2) {
  width: percentage($branchesTableSecondRow / 100);
}


#branchesTable tr th:nth-child(3) {
  width: percentage($branchesTableThirdRow / 100);
}


#branchesTable tr th {
  width: percentage(
    (
        100-$branchesTableLastRow - $branchesTableFirstRow - $branchesTableSecondRow - $branchesTableThirdRow
      )/3/100
  );
}

#branchesTable td { 
  padding-top: 2px;
  padding-bottom: 2px;
}

#clientsTable td { 
  padding-top: 2px;
  padding-bottom: 2px;
}

#stationsTable td { 
  padding-top: 2px;
  padding-bottom: 2px;
}

#measurementTasksTable tr th:last-child {
  width: percentage($manufacturersTableLastRow / 100);
}

#measurementTasksTable tr th {
  width: percentage(1/4);
}

#manufacturerTable {
  table-layout: fixed;
}

#manufacturerTable tr th:last-child {
  width: percentage($manufacturersTableLastRow / 100);
}

#manufacturersTable tr th {
  width: percentage((100-$manufacturersTableLastRow)/3/100);
}

#updateGroupsTable tr th:last-child {
  width: percentage($updateGroupsTableLastRow / 100);
}

#updateGroupsTable tr th {
  width: percentage(1/3);
}

#referencesTextTable tr td {
  vertical-align: top;
}

#cinputdisabled {
  background-color: #ffffff;
  border-color: #ffffff;
}

#cinputactif {
  border-color: blue;
  color: #ffffff;
}

#vehicleslistitem {
  padding-top: 0;
  padding-bottom: 0;
}

#donglelistitem {
  padding-top: 0;
  padding-bottom: 0;
}

#uploaddiagdatabase {
  padding-left: 10px;
}

#uploaddiagdatabasebtn {
  padding-top: 10px;
  padding-left: 10px;
}

#evaluationpagination {
  padding-top: 10px;
}

.row-result-item {
  padding-bottom: 10px;
}

.copy-to-clipboard-icon {
  text-align: center;
  height: 24px;
}

.icon-btn {
  cursor: pointer;
}

.select-nav-header {
  width: 165px;
  margin-right: 10px;
}

.input-car-style {
  max-width: 300px;
}

.c-footer img {
  height: 30px;
}

.login-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.icon-list-style {
  padding-right: 5px;
}

.error-alert-style {
  padding-top: 5px;
}

.list-item-style {
  padding-top: 0px !important;
  padding-bottom: 2px !important;
  padding-left: 0px !important;
}

.select-filter {
  width: 200px;
  margin-right: 0px !important;
  margin-left: 10px;
}

.span-center {
  vertical-align:middle;
}

.button-filter {
  margin: 0px !important;
  padding: 0px !important;
  width: 14px;
  height: 14px;
  justify-content: flex-start;
  align-items: center;
  vertical-align: middle;
}

.icon-sort {
  vertical-align: top;
  width: 13px;
  height: 13px;
}

.col-filter {
 margin: 0px !important;
 margin-top: 3px !important;
}

.row-filter {
   margin: 0px !important;
   margin-right: 0px !important;
}

.th-results{
  padding : 5px !important;
  vertical-align: middle !important
}

.th-results-top-aligned{
  padding : 5px !important;
  vertical-align: top !important
}

.dropdown-filter{padding: 0px;}

.fixed-table {
  word-wrap: break-word; 
  table-layout:fixed;
  width: 100%;
}

.th-text {
  color: #768192 !important;
  font-weight: bold !important;
}

.fixed-button {
  word-wrap: break-word; 
  width: 100%;
}



.dropdown-toggle::after {
  display:none;
}






// ==== Loading-Spinner ====
// = Vars:
// Sizes (Container/Font/...)
$load-spin-cont-size: 15vh;
$load-spin-font-size: 2rem;
$load-spin-margin-top: 10px;
// Opacity:
$load-spin-op-low: 0.2;
$load-spin-op-high: 1.0;

@keyframes rotate-loading {
    0%   {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
    100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
}

@-moz-keyframes rotate-loading {
    0%   {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
    100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
}

@-webkit-keyframes rotate-loading {
    0%   {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
    100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
}

@-o-keyframes rotate-loading {
    0%   {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
    100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
}

@keyframes rotate-loading {
    0%   {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
    100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
}

@-moz-keyframes rotate-loading {
    0%   {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
    100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
}

@-webkit-keyframes rotate-loading {
    0%   {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
    100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
}

@-o-keyframes rotate-loading {
    0%   {transform: rotate(0deg);-ms-transform: rotate(0deg); -webkit-transform: rotate(0deg); -o-transform: rotate(0deg); -moz-transform: rotate(0deg);}
    100% {transform: rotate(360deg);-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); -o-transform: rotate(360deg); -moz-transform: rotate(360deg);}
}

@keyframes loadingText-opacity {
    0%  {opacity: $load-spin-op-low}
    20% {opacity: $load-spin-op-low}
    50% {opacity: $load-spin-op-high}
    100%{opacity: $load-spin-op-low}
}

@-moz-keyframes loadingText-opacity {
    0%  {opacity: $load-spin-op-low}
    20% {opacity: $load-spin-op-low}
    50% {opacity: $load-spin-op-high}
    100%{opacity: $load-spin-op-low}
}

@-webkit-keyframes loadingText-opacity {
    0%  {opacity: $load-spin-op-low}
    20% {opacity: $load-spin-op-low}
    50% {opacity: $load-spin-op-high}
    100%{opacity: $load-spin-op-low}
}

@-o-keyframes loadingText-opacity {
    0%  {opacity: $load-spin-op-low}
    20% {opacity: $load-spin-op-low}
    50% {opacity: $load-spin-op-high}
    100%{opacity: $load-spin-op-low}
}

.loadingContainer { 
//  height: $load-spin-cont-size;
  position: absolute;
//  width: $load-spin-cont-size;
//  border-radius: 100%;
//  margin: 40px auto;
  top: calc(50% - #{$load-spin-cont-size / 2});
  left: calc(50% - #{$load-spin-cont-size / 2});
  cursor: default;
}

.loading {
  height: $load-spin-cont-size;
//  position: relative;
  width: $load-spin-cont-size;
  border-radius: 100%;

  border: 3px solid transparent;
  border-color: transparent $dekra-primary transparent $dekra-primary;
  -moz-animation:           rotate-loading 1.5 linear 0s infinite normal;
  -moz-transform-origin:    50% 50%;
  -o-animation:             rotate-loading 1.5s linear 0s infinite normal;
  -o-transform-origin:      50% 50%;
  -webkit-animation:        rotate-loading 1.5s linear 0s infinite normal;
  -webkit-transform-origin: 50% 50%;
  animation:                rotate-loading 1.5s linear 0s infinite normal;
  transform-origin:         50% 50%;
}

.loadingContainer .loading {
    -webkit-transition:   all 0.5s ease-in-out;
    -moz-transition:      all 0.5s ease-in-out;
    -ms-transition:       all 0.5s ease-in-out;
    -o-transition:        all 0.5s ease-in-out;
    transition:           all 0.5s ease-in-out;
}

#loadingText {
    -moz-animation:     loadingText-opacity 2s linear 0s infinite normal;
    -o-animation:       loadingText-opacity 2s linear 0s infinite normal;
    -webkit-animation:  loadingText-opacity 2s linear 0s infinite normal;
    animation:          loadingText-opacity 2s linear 0s infinite normal;
    color: $dekra-primary;
    font-size: $load-spin-font-size;
    font-weight: bold;
    margin-top: calc(#{$load-spin-cont-size / 2} - #{$load-spin-font-size});
    opacity: 1;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 0;
    width: $load-spin-cont-size;
}