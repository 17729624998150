$dekra-primary: #1a6e3c;
$dekra-third: #a8c4b4;
$dekra-success: rgb(87, 171, 39);
$dekra-danger: rgb(242, 0, 0);
$dekra-info: rgb(255, 213, 0);
$dekra-info2: rgb(0, 85, 255);
$dekra-warning: rgb(238, 127, 0);

$theme-colors: (
  "primary": $dekra-primary,
  "third": $dekra-third,
  "success": $dekra-success,
  "danger": $dekra-danger,
  "info": $dekra-info,
  "info2": $dekra-info2,
  "warning": $dekra-warning,
);

$sidebar-dark-brand-bg: $dekra-primary;
$sidebar-dark-bg: #fff;

$sidebar-dark-nav-title-color: #585858;
$sidebar-dark-nav-link-color: #585858;
$sidebar-dark-nav-link-icon-color: #585858;

$sidebar-dark-nav-link-hover-bg: rgba(0, 0, 0, 0.05);
$sidebar-dark-nav-link-hover-color: $dekra-primary;
$sidebar-dark-nav-link-hover-icon-color: $dekra-primary;

$sidebar-dark-nav-link-active-bg: rgba(0, 0, 0, 0.05);
$sidebar-dark-nav-link-active-color: $dekra-primary;
$sidebar-dark-nav-link-active-icon-color: $dekra-primary;
